/* You can add global styles to this file, and also import other style files */
@import "../styles-theme/mixins";
$color_primary: #1dc7b2;
$color_secondary_1: #c4efea;
$color_secondary: #ebfffc;
$color_primary_intense: #1fa695;

$color_grey_1: #4a4a4a;
$color_grey_2: #9b9b9b;
$color_grey_3: #9aa5b1;
$color_grey_4: #d0d8e0;
$color_grey_5: #e6e6e6;
$color_grey_6: #f0f0f0;
$color_grey_7: #fbfcfd;
$color_grey_8: #f7f7f7;

$color_yellow: #ffcf57;
$color_orange: #ffab57;
$color_danger: #fd6d6d;
$color_purple: #9013fe;
$color_white: #fff;
$color_black: #000;
$color_custom: #ccc;

$colour_blue: #b3e5fc;
$color_blue_light: #fbfdff;

$color_grey_2_ultra: rgba(155, 155, 155, 0.25); // light_gray with 0.25 opacity
$shadow_color: rgba(0, 0, 0, 0.2);
$shadow_color_light: rgba(228, 228, 228, 0.5);

$border_small_primary: 1px solid $color_primary;
$border_small_ultra_light_grey: 1px solid $color_grey_5;
$full_width: 100%;
$form_input_font_size: 16px;

$font_family: "AvenirNext";
$font_bold: 700;
$font_ultra_bold: 800;
$font_demi_bold: 600;
$font_normal: 400;
$font_light: 300;

$h1_size: 36px;
$h2_size: 30px;
$h3_size: 24px;
$h4_size: 20px;
$h5_size: 18px;
$p_size: 16px;

//Extra font size
$font_size_1: 72px;
$font_size_2: 60px;
$font_size_3: 48px;
$font_size_4: 14px;
$font_size_5: 12px;

//SHADOWS
$shadow_1: 0px 1px 3px 0px $shadow_color;
$shadow_2: 0px 4px 6px 0px $shadow_color;
$shadow_3: 0px 5px 15px 0px $shadow_color;
$shadow_4: 0px 10px 24px 0px $shadow_color;
$shadow_5: 0px 15px 35px 0px $shadow_color;
$shadow_6: 0 1px 21px 0 $shadow_color_light;

//ICON
$large_icon_size: 25px;
$medium_icon_size: 12px;
$small_icon_size: 10px;

$input_height: 48px;
$input_border_radius: 5px;
$input_font_size: $form_input_font_size;
$input_padding: 12px 16px;
$input_margin: 0px 0px 0px 0px;
$input_transition: 0.5s;
$input_max_width: 626px;

$textarea_height: 150px;
$textarea_padding: 12px 16px;
$textarea_font_size: $form_input_font_size;
$textarea_radius: 5px;
$textarea_transition: 0.5s;
$textarea_placeholder_font_size: $form_input_font_size;

$check_box_user_select: none;
$check_box_font_size: $p_size;
$check_box_width: 32px;
$check_box_height: 32px;
$check_box_border_radius: 6px;
$check_box_shadow: none;
$check_box_background: $color_white;
$checkbox_border: 1px solid $color_grey_5;

$button_shadow: none;
$button_font_size: 22px;
$button_font_weight: $font_demi_bold;
$button_height: 48px;
$button_width: 139px;
$button_radius: 6px;
$button_active_color: $color_primary_intense;
$button_padding: 16px;
$button_long_width: 269px;
//card
$card_padding: 56px;
$card_shadow: $shadow_1;
$card_radius: 6px;
$default_padding: 16px;
//Search area
$search_input_radius: 9px;
$search_input_height: 34px;
$search_input_shadow: $shadow_1;
$search_label: 12px;

//Input result box
$reslut_box_radius: 9px;

//zorro override
$date_range_shadow: $shadow_1;
$date_range_height: 34px;
$date_range_font_size: $form_input_font_size;

$input_select_height: 34px;
$font_select_drop_box: 14px;
$large_progress: 20px;
$large_medium: 15px;
$small_progress: 12px;

$modal_radius: 5px;
$modal_padding: 32px 40px 32px 40px;
$modal_border: 7px solid $color_primary;
$modal_shadow: 0px 15px 35px 1px rgba(125, 125, 125, 0.2);
$modal_mask: rgba(208, 208, 208, 0.6);

//floating-footer
$footer_shadow: 0 1px 2px 1px #c4c4c4;
$footer_width: 900px;
$footer_height: 73px;
$footer_radius: $modal_radius;

:root {
    --color_primary: #{$color_primary};
    --color_secondary_1: #{$color_secondary_1};
    --color_secondary: #{$color_secondary};
    --color_primary_intense: #{$color_primary_intense};
    --color_custom: #{$color_custom};
    --color_grey_1: #{$color_grey_1};
    --color_grey_1: #{$color_grey_1};
    --color_grey_2: #{$color_grey_2};
    --color_grey_3: #{$color_grey_3};
    --color_grey_4: #{$color_grey_4};
    --color_grey_5: #{$color_grey_5};
    --color_grey_6: #{$color_grey_6};
    --color_grey_7: #{$color_grey_7};
    --color_grey_8: #{$color_grey_8};
    --color_yellow: #{$color_yellow};
    --color_danger: #{$color_danger};
    --color_purple: #{$color_purple};
    --color_white: #{$color_white};
    --color_black: #{$color_black};
    --color_blue_light: #{$color_blue_light};
    --color_grey_2_ultra: #{$color_grey_2_ultra}; // light_gray with 0.25 opacity
    --shadow_color: #{$shadow_color};
    --shadow_color_light: #{$shadow_color_light};
    --border_small_primary: #{$border_small_primary};
    --border_small_ultra_light_grey: #{$border_small_ultra_light_grey};
    --full_width: #{$full_width};
    --form_input_font_size: #{$form_input_font_size};
    --font_family: #{$font_family};
    --font_bold: #{$font_bold};
    --font_ultra_bold: #{$font_ultra_bold};
    --font_demi_bold: #{$font_demi_bold};
    --font_normal: #{$font_normal};
    --font_light: #{$font_light};
    --h1_size: #{$h1_size};
    --h2_size: #{$h2_size};
    --h3_size: #{$h3_size};
    --h4_size: #{$h4_size};
    --h5_size: #{$h5_size};
    --p_size: #{$p_size};
    //Extra font size
    --font_size_1: #{$font_size_1};
    --font_size_2: #{$font_size_2};
    --font_size_3: #{$font_size_3};
    --font_size_4: #{$font_size_4};
    --font_size_5: #{$font_size_5};
    //SHADOWS
    --shadow_1: #{$shadow_1};
    --shadow_2: #{$shadow_2};
    --shadow_3: #{$shadow_3};
    --shadow_4: #{$shadow_4};
    --shadow_5: #{$shadow_5};
    --shadow_6: #{$shadow_6};
}
