@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
	// For Internet explorer
	// .header-wrapper{
	//     top: 234.5px !important;
	// }
	.b-input-search {
		line-height: 1px !important;
	}
	//   .shifts-header {
	//     position: fixed !important;
	//     height: 120px !important;
	//     left: 112px !important;
	//     top: 209px !important;
	//     z-index: 1 !important;
	//   }
	//   .applicants-header {
	//     position: fixed !important;
	//     top: 209px !important;
	//     left: 847px !important;
	//     overflow-x: scroll !important;
	//     overflow-y: hidden !important;
	//     -ms-overflow-style: none;
	//     width: calc(100% - 882px);
	//     perspective: 200px !important;
	//     -webkit-perspective: 200px !important;
	//     -moz-perspective: 200px !important;
	//     transform: translate3d(0, 0, 0) !important;
	//     padding-top: 34px;
	//     height: 117px;
	//   }
	//   .strike-img {
	//     margin-left: 27px !important;
	//     z-index: 1;
	//   }
	//   .body-wrapper {
	//     margin-left: 733px !important;
	//   }
	//   .body-wrapper .sticky {
	//     position: fixed !important;
	//     z-index: 2 !important;
	//     top: 326px !important;
	//     left: 118px !important;
	//     height: calc(100% - 390px);
	//     overflow-y: scroll !important;
	//     overflow-x: hidden !important;
	//     -ms-overflow-style: none;
	//     perspective: 200px !important;
	//     -webkit-perspective: 200px !important;
	//     -moz-perspective: 200px !important;
	//     transform: translate3d(0, 0, 0) !important;
	//   }
	//   .job-type-wrapper .job-type-details li {
	//     padding: 0px 0px 0px 3px !important;
	//     text-indent: 0px !important;
	//     margin-left: 16px !important;
	//   }
	.b-dot-large, .status-img {
		margin: 0px !important;
	}
	.training-icon img {
		transform: translateX(0px) translateY(61px) !important;
	}
}
