@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
  -moz-border-radius: $radius;
  border-radius: $radius;
}

@mixin box-shadow($shadow) {
  box-shadow: $shadow;
  -webkit-box-shadow: $shadow;
  -moz-box-shadow: $shadow;
}
@mixin transition($transition) {
  -webkit-transition: $transition; /* Safari */
  transition: $transition;
}
@mixin user-select($user_select) {
  // The user-select property specifies whether the text of an element can be selected.
  -webkit-user-select: $user_select; /* Safari 3.1+ */
  -moz-user-select: $user_select; /* Firefox 2+ */
  -ms-user-select: $user_select; /* IE 10+ */
  user-select: $user_select; /* Standard syntax */
}
@mixin transform($transform) {
  -ms-transform: $transform; /* IE 9 */
  -webkit-transform: $transform; /* Safari 3-8 */
  transform: $transform;
}
@mixin sticky() {
    position: -webkit-sticky;
    position: -moz-sticky;
    position: -o-sticky;
    position: -ms-sticky;
    position: sticky;
}
