//FONT
h1,
h2,
h3,
h4,
h5,
p,
.b-Eh1,
.b-Eh2,
.b-Eh3,
.b-Eh4,
.b-Eh5,
li {
	color: $color_grey_1;
	font-family: $font_family;
	margin-bottom: 1px;
	font-weight: $font_normal;
}
h1 {
	font-size: $h1_size;
}
h2 {
	font-size: $h2_size;
}
h3 {
	font-size: $h3_size;
}
h4 {
	font-size: $h4_size;
}
h5 {
	font-size: $h5_size;
}
p {
	font-size: $p_size !important;
}
//Extra font
.b-Eh1 {
	font-size: $font_size_1;
}
.b-Eh2 {
	font-size: $font_size_2;
}
.b-Eh3 {
	font-size: $font_size_3;
}
.b-Eh4 {
	font-size: $font_size_4;
}
.b-Eh5 {
	font-size: $font_size_5;
}
.b-font-normal {
	font-weight: $font_normal;
}
.b-font-demibold {
	font-weight: $font_demi_bold;
}
.b-font-bold {
	font-weight: $font_bold;
}
//Colors class
.b-primary {
	color: $color_primary !important;
}
.b-danger {
	color: $color_danger !important;
}
.b-yellow {
	color: $color_yellow !important;
}
.b-warning {
	color: $color_orange !important;
}
.b-light {
	color: $color_grey_2 !important;
}
.b-white {
	color: $color_white !important;
}
.b-background-primary-light {
	background: $color_secondary !important;
}
.b-background-danger {
	background: $color_danger !important;
}
.b-background-primary {
	background: $color_primary !important;
}
.b-background-yellow {
	background: $color_yellow !important;
}
.b-background-grey-5 {
	background: $color_grey_5 !important;
}
//ICONS
.b-small-icon {
	width: $small_icon_size;
}
.b-medium-icon {
	width: $medium_icon_size;
}
.b-large-icon {
	width: $large_icon_size;
}
//Shadows
.b-shadow-1 {
	@include box-shadow($shadow_1 !important);
}
.b-shadow-2 {
	@include box-shadow($shadow_2 !important);
}
.b-shadow-3 {
	@include box-shadow($shadow_3 !important);
}
.b-shadow-4 {
	@include box-shadow($shadow_4 !important);
}
.b-shadow-5 {
	@include box-shadow($shadow_5 !important);
}
//INPUT
.input-wrapper {
	//Wrap an input with a button or icon on same line
	display: flex;
}
// label {
//     display: block;
//     font-size: $font_size_5;
//     font-weight: $font_demi_bold;
//     margin-bottom: 15px;
//     text-transform: uppercase;
// }
label {
	display: block;
	font-size: $h4_size;
	font-weight: $font_demi_bold;
	margin-bottom: 15px;
}
.b-input {
	max-width: $input_max_width;
	width: $full_width;
	height: $input_height;
	@include border-radius($input_border_radius);
	font-size: $input_font_size;
	color: $color_grey_1;
	white-space: nowrap !important;
	overflow: hidden;
	text-overflow: ellipsis;
	-webkit-appearance: none !important;
	&::placeholder {
		color: $color_grey_2_ultra !important;
		font-size: $input_font_size;
	}
	&:disabled,
	&[disabled] {
		opacity: 0.4;
	}
}
.b-input[type="text"]:focus,
.b-input[type="email"]:focus,
.b-input[type="number"]:focus,
.b-input[type="password"]:focus {
	border: $border_small_primary;
}
.b-input[type="text"],
.b-input[type="email"],
.b-input[type="number"],
.b-input[type="password"],
select {
	// width: $full_width;
	padding: $input_padding;
	margin: $input_margin;
	display: inline-block;
	border: solid 1px $color_white;
	@include border-radius($input_border_radius);
	box-sizing: border-box;
	background-color: $color_grey_7;
	@include transition($input_transition);
	outline: none;
	-webkit-appearance: none !important;
}
.b-input-white[type="text"],
.b-input-white[type="number"],
.b-input-white[type="email"],
.b-input-white[type="password"],
select {
	background-color: $color_white;
	border: solid 1px $color_grey_5;
}
//INPUT SEARCH
.b-input-search {
	height: $search_input_height;
	font-weight: $font_demi_bold !important;
}
.b-input-search[type="text"]:focus {
	border: $border_small_primary;
}
.b-input-search::placeholder {
	font-weight: $font_demi_bold;
	color: $color_grey_2_ultra !important;
}
.b-input-search[type="text"],
select {
	background-color: $color_white;
	@include box-shadow($search_input_shadow);
}
.custom-text {
	font-size: $search_label !important;
	font-weight: $font_demi_bold !important;
	color: $color_grey_2;
	letter-spacing: 2px !important;
	text-transform: uppercase;
}
.b-input-login {
	font-weight: $font_normal !important;
	border: solid 1px $color_grey_5 !important;
	@include border-radius($input_border_radius);
	background: $color_white !important;
	height: 48px !important;
}
.b-input-login[type="text"]:focus {
	border: $border_small_primary;
}
.b-input-login::placeholder {
	color: $color_grey_2 !important;
}
.input-underlined {
	border-bottom: 1px solid #d0d8e0;
	.b-input {
		padding-right: 0px;
		border: none !important;
		padding-bottom: 5px;
		padding-top: 0px;
		height: auto !important;
		background: none !important;
	}
	display: flex;
	align-items: baseline;
	width: 168px !important;
	min-width: 168px !important;
}
.label-subtitle {
	margin-top: 8px;
	margin-bottom: 16px;
	color: $color_grey_2 !important;
}
.b-result-box {
	overflow-y: scroll;
	@include border-radius($reslut_box_radius);
	box-shadow: $shadow_3;
	background-color: $color_white;
	// position: absolute;
	z-index: 3;
	min-width: 154px;
	ul {
		padding: 0px !important;
		padding-top: 14px !important;
	}
	li {
		.suggestion-li-wrapper {
			display: flex;
			justify-content: space-between;
			align-items: center;
		}
		.suggestion-info {
			display: flex;
			align-items: center;
			.suggestion-avatar {
				margin-right: 12px;
				border: solid 1px #fff;
			}
		}
		width: 100%;
		padding-left: 16px;
		padding-right: 16px;
		padding-bottom: 14px;
		padding-top: 14px;
		font-size: $p_size !important;
		color: $color_grey_1 !important;
		list-style-type: none !important;
		cursor: pointer;

		&:hover {
			background: $color_primary;
			color: $color_white !important;
			span {
				color: $color_white !important;
			}
			p {
				color: $color_white !important;
			}
		}
	}
	/* width */
	&::-webkit-scrollbar {
		width: 0px !important;
	}
}
//CHECKBOX
.b-checkbox {
	display: block;
	position: relative;
	padding-left: 47px;
	padding-top: 3px;
	margin-bottom: 12px;
	cursor: pointer;
	@include user-select($check_box_user_select);
	font-size: $check_box_font_size !important;
	color: $color_grey_1;
	width: 78px;
	margin: 0px !important;
	font-weight: $font_normal !important;
	text-transform: none !important;
}
/* Hide the browser's default checkbox */

.b-checkbox input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}
/* Create a custom checkbox */
.checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: $check_box_height !important;
	width: $check_box_width !important;
	border-radius: $check_box_border_radius !important;
	@include box-shadow($check_box_shadow);
	background-color: $check_box_background;
	border: $checkbox_border;
}
.checkmark-disabled {
	background: $color_grey_8 !important;
}
/* On mouse-over, add a grey background color */
//   .b-checkbox:hover input ~ .checkmark {
//     background-color: #ccc;
//   }
/* When the checkbox is checked, add a blue background */
.b-checkbox input:checked ~ .checkmark {
	background-color: $check_box_background;
	background: $color_primary;
	border: 1px solid $color_primary !important;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
	content: "";
	position: absolute;
	display: none;
}
/* Show the checkmark when checked */
.b-checkbox input:checked ~ .checkmark:after {
	display: block;
}
/* Style the checkmark/indicator */
.b-checkbox .checkmark:after {
	left: 11px !important;
	top: 6px !important;
	width: 8px !important;
	height: 15px !important;
	border: solid $color_white !important;
	border-width: 0 3px 3px 0 !important;
	-webkit-transform: rotate(45deg) !important;
	transform: rotate(45deg) !important;
}
.checkbox-wrapper {
	display: flex;
	.b-checkbox {
		margin-right: 52px !important;
	}
}
.b-checkbox-login {
	font-size: $p_size !important;
	color: $color_grey_2 !important;
	padding-left: 29px !important;
	padding-top: 0px !important;
	.checkmark {
		width: 22px !important;
		height: 22px !important;
		&:after {
			left: 6px !important;
			top: 2px !important;
			width: 7px !important;
			height: 13px !important;
		}
	}
}
//BUTTON
.b-button {
	border: none;
	text-align: center;
	text-decoration: none;
	font-size: $button_font_size;
	font-weight: $button_font_weight;
	color: $color_white;
	display: inline-block;
	margin: 1px 2px;
	cursor: pointer;
	min-width: $button_width;
	height: $button_height;
	@include border-radius($button_radius);
	@include box-shadow($button_shadow);
	background-color: $color_primary;
	outline: none;
	@include transition(0.1s);
	border: 1px solid $color_primary;
	padding-left: $button_padding;
	padding-right: $button_padding;
	&:active {
		background-color: $button_active_color;
		@include box-shadow($button_shadow);
		@include transform(translateY(1px));
	}
	&:disabled,
	&[disabled] {
		background-color: $color_grey_2;
		border: 1px solid $color_grey_2;
		opacity: 0.2;
	}
}
.group-buttons {
	display: flex;
	.b-button {
		margin: 13px !important;
	}
	app-clib-button {
		margin-right: 16px !important;
		&:last-child {
			margin-right: 0px !important;
		}
	}
}
.button-outline {
	color: $color_primary !important;
	background: none !important;
}
.b-button-outline-light-grey {
	color: $color_grey_2 !important;
	border-color: $color_grey_2 !important;
	background: none !important;
	font-weight: normal;
}
.b-button-outline-dark {
	color: $color_grey_1 !important;
	border-color: $color_grey_1 !important;
	background: none !important;
	font-weight: $font_demi_bold;
}
.button-outline-small {
	min-width: 80px !important;
	height: 46px !important;
	font-size: $p_size;
}
.b-button-long {
	width: $button_long_width;
}
.b-secundary-button {
	font-size: $font_size_5 !important;
	min-width: 61px !important;
	height: 22px;
	@include border-radius(9px);
	background-color: #f1f1f1;
	color: #b0b0b0;
	border: none !important;
	&:active {
		background: $color_grey_7 !important;
	}
}
.b-button-danger {
	background: $color_danger !important;
	border: 1px solid $color_danger;
}
.b-button-yellow {
	background: $color_yellow !important;
	border: 1px solid $color_yellow;
}
.b-button-medium {
	height: 42px !important;
	font-size: $p_size !important;
	font-weight: $font_normal !important;
	min-width: 124px !important;
}
.b-button-small {
	height: 27px !important;
	font-size: $font_size_5;
	font-weight: $font_demi_bold !important;
	min-width: 109px !important;
}
.b-button-small-round {
	background-color: $color_white;
	border: 1px solid $color_grey_3;
	color: $color_grey_3;
	font-weight: $font_demi_bold;
	font-size: $p_size;
	height: 28px;
	min-width: 102px !important;
	@include border-radius(11px);
	padding-left: 9px !important;
	padding-right: 9px !important;
	&:active {
		background: $color_grey_7 !important;
	}
}
//LINK
.b-link {
	font-size: $p_size;
	color: $color_primary;
	text-decoration: underline !important;
	font-weight: $font_demi_bold;
	cursor: pointer;
}
.b-link-grey {
	font-size: $h5_size;
	font-weight: 500 !important;
	color: $color_grey_2 !important;
	text-decoration: underline !important;
}
.dark-link {
	text-decoration: underline !important;
	color: $color_black !important;
	font-size: $font_size_4;
	cursor: pointer;
}
//LINE
hr {
	border: solid 0.5px $color_grey_5;
}
//ELEMENT
.hide {
	display: none;
}
//TEXTAREA
.b-textarea-green-font {
	outline: none;
	width: $full_width;
	max-width: $input_max_width;
	height: $textarea_height;
	padding: $textarea_padding;
	box-sizing: border-box;
	border: solid 1px $color_white;
	background-color: $color_grey_7;
	font-size: $textarea_font_size !important;
	resize: none;
	color: $color_primary;
	@include transition($textarea_transition);
	@include border-radius($textarea_radius);
	&::placeholder {
		color: $color_grey_2_ultra;
		font-size: $textarea_placeholder_font_size;
	}
	&:focus {
		border: $border_small_primary;
	}
}
.b-textarea {
	outline: none;
	width: $full_width;
	max-width: $input_max_width;
	height: $textarea_height;
	padding: $textarea_padding;
	box-sizing: border-box;
	border: solid 1px $color_white;
	background-color: $color_grey_7;
	font-size: $textarea_font_size !important;
	resize: none;
	color: $color_grey_1;
	@include transition($textarea_transition);
	@include border-radius($textarea_radius);
	&::placeholder {
		color: $color_grey_2_ultra;
		font-size: $textarea_placeholder_font_size;
	}
	&:focus {
		border: $border_small_primary;
	}
}
.b-textarea-white {
	background-color: $color_white;
	border: solid 1px $color_grey_5;
}

//CARD
.b-card {
	@include border-radius($card_radius);
	@include box-shadow($shadow_1);
	padding: $card_padding;
	background: $color_white;
}
.b-card-large {
	@include border-radius($card_radius);
	background: $color_white;
	padding: 0px !important;
	@include box-shadow($shadow_2);
}
.form-card {
	@include border-radius($card_radius);
	background: $color_white;
	border: solid 1px $color_grey_5;
	padding: 9px 24px 9px 24px;
	width: 100%;
}
.error-box {
	padding: 5px;
	color: $color_danger !important;
	text-align: right;
	font-size: $font_size_4 !important;
	a {
		color: $color_danger;
		font-weight: 600;
	}
}
.regular-padding {
	padding: $default_padding;
}
//Center text button
.text-center {
	text-align: center;
}
//RIght text
.text-right {
	text-align: right;
}
//Left text
.text-left {
	text-align: left;
}
//Badge
.badge {
	@include border-radius(11px);
	padding: 2px 12px 2px 12px;
	text-align: center;
	width: 103px;
}
.badge-danger {
	background: $color_danger;
	color: $color_white;
}
.badge-primary {
	background: $color_primary;
	color: $color_white;
}
.badge-warning {
	background: $color_yellow;
	color: $color_grey_1;
}
//Tags
.tag {
	@include border-radius(5px);
	padding: 6px 9px 6px 9px;
	text-align: center;
	width: 125px;
	font-size: $font_size_5 !important;
	font-weight: $font_demi_bold !important;
}
.tag-light {
	color: $color_grey_3;
	background: $color_grey_8;
}
.tag-danger {
	background: $color_danger;
	color: $color_white;
}
.tag-primary {
	background: $color_primary;
	color: $color_white;
}
.tag-warning {
	background: $color_yellow;
	color: $color_white;
}
.tag-custom {
	background: $color_custom;
	color: $color_white;
}
//DOTS

.application-dot {
	width: 10px;
	height: 10px;
	border-radius: 50%;
	margin-right: 2px;
	display: inline-block;
}


.dot-green {
	background-color: $color_primary;
}
.dot-grey {
	background-color: $color_grey_5;
}

.b-dot-large {
	min-width: 14px;
	height: 14px;
	background: $color_primary;
	@include border-radius(50px);
}
.my-bullet {
	width: 10px;
	height: 10px;
	background: #000;
	border-radius: 30px;
	padding: 5px;
}
.row-spacing {
	margin-top: 32px;
	margin-bottom: 32px;
}

.row-spacing-custom {
	margin-top: 16px;
	margin-bottom: 7px;
}
.title-sticky {
	@include sticky();
	top: 0;
}
::-webkit-scrollbar {
	width: 6px; /* for vertical scrollbars */
	height: 6px; /* for horizontal scrollbars */
}
::-webkit-scrollbar-track {
	background: $color_grey_7;
}
::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
	background: $color_grey_4;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.cursor-pointer {
	cursor: pointer;
}
//Link Loading animation
app-loading-spinner.link-loading .small {
	height: 15px !important;
	width: 39px !important;
}
.noselect {
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none; /* Non-prefixed version, currently
supported by Chrome and Opera */
}
.text-spacing {
	margin-top: 16px;
	margin-bottom: 16px;
}
.no-roles {
	width: 407px;
	margin: auto;
	margin-top: 151px;
	text-align: center;
	button {
		margin-top: 56px;
	}
}
.no-margin-bottom {
	margin-bottom: 0px !important;
}
.no-margin-top {
	margin-top: 0px !important;
}
.no-margin-right {
	margin-right: 0px !important;
}
.no-margin-left {
	margin-left: 0px !important;
}
.b-border-top {
	border-top: 1px solid $color_grey_5;
}
.b-border-bottom {
	border-bottom: 1px solid $color_grey_5;
}

@media (max-width: 600px) {
	.mobile-hide {
		display: none !important;
	}
	.no-roles {
		width: 70% !important;
	}
}
.selected-tool-tip .ant-tooltip-inner{
	text-align: center;
	width: 156px;
}

.margin-bottom-32 {
	margin-bottom: 32px;
}
.margin-top-32 {
	margin-top: 32px;
}
.margin-bottom-16 {
	margin-bottom: 16px;
}
.margin-top-16 {
	margin-top: 16px;
}
.margin-right-16 {
	margin-right: 16px;
}
.margin-right-8 {
	margin-right: 8px;
}
.margin-bottom-8 {
	margin-bottom: 8px !important;
}
.margin-top-8 {
	margin-top: 8px;
}
.margin-bottom-4 {
	margin-bottom: 4px !important;
}
.margin-top-4 {
	margin-top: 4px;
}

.text-right {
	text-align: right;
}
.text-left {
	text-align: left;
}

.aligned-flex {
	display: flex;
	align-items: center;
}
.align-flex-top {
	align-items: flex-start !important;
}
.space-between {
	justify-content: space-between;
}
.flex-center {
	justify-content: center;
}
.flex-right {
	justify-content: end;
}

