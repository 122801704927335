//SELECT TAG AUTOCOMPLETE ZORRO OVERIDE
.b-form-select-gray {
    .ant-select-selection--multiple {
        height: $input_height !important;
        background-color: $color_grey_7;
        border-color: $color_grey_7;
        li {
            height: 37px !important;
            line-height: 32px !important;
            font-size: $form_input_font_size !important;
            color: $color_grey_2 !important;
            padding-right: 30px !important;
            margin-top: 6px !important;
        }
        .ant-select-selection__choice {
            background-color: $color_white !important;
            @include border-radius(61px !important);
        }
        .ant-select-selection__choice__remove {
            font-size: 17px !important;
        }
        .ant-select-selection__rendered {
            margin-left: $form_input_font_size !important;
        }
    }
    .ant-select-open .ant-select-selection {
        @include box-shadow(none !important);
        border: $border_small_primary !important;
    }
    .ant-select-selection:hover {
        border-color: $color_grey_7;
    }
    .ant-select-selection:focus {
        border-color: $color_grey_7;
        @include box-shadow(none !important);
    }
    .ant-select-selection__placeholder {
        font-size: $form_input_font_size !important;
        margin-top: -1px !important;
    }
}
//Progress bar steps
.b-nz-steps-large {
    .ant-steps {
        margin-bottom: 33px;
        margin-top: 20px;
        width: 92%;
        margin-left: auto;
        margin-right: 2.1%;
    }
    .ant-steps-item-title {
        font-size: 36px !important;
        color: $color_grey_1 !important;
        font-weight: $font_demi_bold;
        font-family: $font_family !important;
        padding-right: 37px !important;
    }
    .ant-steps-item-title::after {
        //background-color: rgb(224, 224, 224) !important;
        //  background-color: $color_primary !important;
    }
    .ant-steps-item-icon {
        display: none !important;
    }
    .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after,
    .ant-steps-item-finish > .ant-steps-item-tail:after {
        background-color: $color_primary !important;
    }
    .ant-steps-item-wait > .ant-steps-item-content > .ant-steps-item-title {
        color: $color_grey_2 !important;
    }
    .ant-steps-item-title::after {
        top: 18px !important;
    }
    .ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
        margin-right: 37px !important;
    }
}
.b-nz-steps-small {
    top: 28px;
    position: relative;
    left: 37px;
    .ant-steps-item {
        flex: none !important;
    }
    .ant-steps-item-title {
        font-size: $h4_size !important;
        color: $color_grey_2 !important;
        font-weight: $font_normal;
        font-family: $font_family !important;
    }
    .ant-steps-item-content {
        position: relative;
        top: -47px;
    }
    .ant-steps-dot .ant-steps-item-tail {
        top: 5px !important;
        width: 105% !important;
        margin: 0 0 0 68px !important;
    }
    .ant-steps-icon-dot {
        width: 14px !important;
        height: 14px !important;
    }
    .ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
    .ant-steps-item-process .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot,
    .ant-steps-item-finish > .ant-steps-item-content > .ant-steps-item-title:after,
    .ant-steps-item-finish > .ant-steps-item-tail:after {
        background-color: $color_primary !important;
    }
    .ant-steps-item-wait .ant-steps-item-icon > .ant-steps-icon .ant-steps-icon-dot {
        background-color: #d0d8e0 !important;
    }
}
// Date range
.anticon {
    font-size: 19px !important;
    margin-top: -3px !important;
    cursor: pointer;
    background: $color_white !important;
}
.ant-calendar-picker-clear {
    margin-top: -9px !important;
    width: 20px;
    height: 20px;
    right: 6px;
    font-size: 19px;
}
.nz-shift-pattern-date-range {
    width: 190px;
    background-color: $color_grey_8 !important;
    border-radius: 5px;
    height: 45px !important;
	border: none;
	.ant-picker-active-bar{
		display: none !important;
	}

}
.ant-picker-focused, .ant-picker:hover{
	border: none !important;
	// @include box-shadow(none !important);
	
}



.nz-override-view-schedule {
	padding:0px !important;
	border-radius: 9px !important;

}
.nz-override-view-schedule-drop {
	padding:0px !important;

}

.nz-override-date-range {
	@include box-shadow($date_range_shadow);
	height: 34px;
	border-radius: 5px;
    border: none !important;
	// width: 186px !important;
	input {
		height: $date_range_height !important;
		font-size: $date_range_font_size;
		font-family: $font_family !important;
		font-weight: $font_demi_bold !important;
	}
	.ant-calendar-range-picker-input {
		font-size: $date_range_font_size;
		font-family: $font_family !important;
		font-weight: $font_demi_bold !important;
		text-align: left;
	}
	.ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-input-disabled) {
		@include box-shadow($date_range_shadow !important);
	}
	.ant-calendar-picker-input {
		padding-left: 16px !important;
		// min-width: 252px;
		.ng-star-inserted::placeholder {
		color: $color_grey_4 !important;
		}
		.ant-calendar-range-picker-separator {
		margin-left: -7px;
		margin-right: 7px;
		}
	}
	.ant-picker-input>input:focus{
		border: none !important;
	}

	.ant-calendar-range-picker-input {
		min-width: 94px !important;
	}
	.ant-picker-active-bar{
		display: none !important;
	}
}
.nz-date-no-icon {
	.ant-picker-suffix{
		display: none !important;
	}
}

.nz-date-no-separator {
    .ant-picker-range-separator {
        display: none !important;
    }
}

.nz-no-cross {
	.anticon-close-circle{
		display: none !important;
	}
}
.nz-override-date-b-input {
	height: $input_height !important;
	@include border-radius($input_border_radius !important);
	font-size: $input_font_size !important;
	color: $color_grey_1 !important;
	background-color: $color_grey_7 !important;
	padding-left: 17px !important;
	@include box-shadow(none !important);
	width: 100%;
    .ant-calendar-picker:focus .ant-calendar-picker-input:not(.ant-picker-input-disabled) {
        @include box-shadow(none !important);
    }
}
.nz-override-date-range-drop {

	right: 0 !important;
	left: 0 !important;
  
	.ant-picker-panel-container {
	  @include border-radius($search_input_radius !important);
	  @include box-shadow($shadow_3 !important);
	}
	.ant-picker-panel {
	  padding: 8px !important;
	  border: none !important;
	}
	.ant-picker-input-wrap {
	  // to hide fields where you type in.
	  display: none !important;
	}
	.ant-picker-range-middle {
	  display: none !important;
	}
	.ant-picker-header-view {
	  
		font-size: $date_range_font_size !important;
		font-weight: $font_normal !important;
		color: $color_grey_1 !important;
		font-family: $font_family !important;
	  
	}
	.ant-picker-body {
	  font-size: $date_range_font_size !important;
	  font-weight: $font_normal !important;
	  color: $color_grey_1 !important;
	  font-family: $font_family !important;
	  border: none !important;
	}
	date-table .ant-picker-cell-inner {
	  border-radius: 30px !important;
	  width: 32px !important;
	  height: 32px !important;
	  line-height: 30px !important;
	}
	.ant-picker-cell-inner:active {
	  background: $color_primary !important;
	}
  
	.ant-picker-cell-today .ant-picker-cell-inner {
	  border-color: $color_primary !important;
	  color: $color_primary !important;
	}
	.ant-picker-range-right .ant-picker-tbody .ant-picker-cell-today .ant-picker-cell-inner {
	  border: none !important;
	  color: #ccc !important;
	}
	.ant-picker-selected-start-date .ant-picker-cell-inner {
	  background: $color_primary !important;
	  color: $color_white !important;
	}
	.ant-picker-selected-end-date .ant-picker-cell-inner {
	  background: $color_primary !important;
	  color: $color_white !important;
	}
	 .ant-picker-cell-inner:before {
	  border: none !important;
	}
	.ant-picker-cell-in-view{
	  border: none !important;
	}
	.ant-picker-content th {
	  text-transform: uppercase !important;
	}
	.ant-picker-prev-year-btn,
	.ant-picker-next-year-btn {
	  display: none !important;
	}
	.ant-picker-prev-month-btn,
	.ant-picker-next-month-btn {
	  font-size: 45px !important;
	  color: $color_primary !important;
	  font-family: $font_family !important;
	  top: -3px;
	  font-weight: $font_demi_bold !important;
	}
	.ant-picker-year-panel-selected-cell .ant-picker-year-panel-year,
	.ant-picker-month-panel-selected-cell .ant-picker-month-panel-month {
	  background: $color_primary !important;
	}
	.ant-picker-header-view button:hover{
	  color: $color_primary !important;
	}
	
	.ant-picker-cell-inner:hover {
	  background: $color_primary !important;
	  color: $color_white !important;
	}
	.ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-end-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-start-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-end:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start-single:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start.ant-picker-cell-range-start.ant-picker-cell-range-end.ant-picker-cell-range-end-near-hover:after, .ant-picker-cell-in-view.ant-picker-cell-range-hover-start:not(.ant-picker-cell-in-range):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):after, .ant-picker-cell-in-view.ant-picker-cell-range-hover:not(.ant-picker-cell-in-range):after{
	  border: none;
	  background: $color_secondary !important;
	}
	.ant-picker-header {
	  border: none !important;
	}
	.ant-picker-footer {
	  display: none !important;
	}
	.ant-picker-cell-selected .ant-picker-cell-inner {
	  background: $color_primary;
	  color: $color_white !important;
	}
	.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
	  background: $color_primary;
	}
}
.nz-date-picker-button {
	border:none !important;
	.ant-picker-input,
	.ant-picker-suffix {
	  display: none !important;
	}
  }
  .ant-picker-super-next-icon, .ant-picker-super-prev-icon{
	  display: none !important;
}
.ant-calendar-picker-icon {
    z-index: 0 !important;
}
.drop-position {
    right: 148px !important;
}
//Input slelect search
.nz-override-input-select {
    width: 198px;

    .ant-select-selection {
        @include box-shadow($date_range_shadow);
        border: none !important;
        height: $input_select_height !important;
        font-size: $date_range_font_size;
        font-family: $font_family !important;
        font-weight: $font_demi_bold !important;
    }
    .ant-select-selection:hover {
        border-color: $color_grey_7;
    }
    .ant-select-selection:focus {
        border-color: $color_grey_7;
        @include box-shadow($date_range_shadow);
    }
    .ant-select-selection__placeholder {
        font-size: $form_input_font_size !important;
    }
    .ant-select-selection__rendered {
        line-height: 34px !important;
    }
    .ant-select-selection__clear {
        right: 17px !important;
    }
}

.nz-override-input-select-drop {
    width: 257px !important;
    margin-top: 18px !important;
    left: -26px !important;
    @include border-radius($search_input_radius);
    @include box-shadow($shadow_3);
    .ant-select-dropdown-menu {
        margin-top: 7px !important;
        margin-bottom: 7px !important;
    }
    .ant-select-dropdown-menu-item {
        font-size: $font_select_drop_box;
        font-family: $font_family !important;
        font-weight: $font_normal !important;
        padding: 16px 24px !important;
    }
    .ant-select-dropdown-menu-item-selected,
    .ant-select-dropdown-menu-item-selected:hover {
        background: $color_primary !important;
        color: $color_white !important;
    }
    .ant-select-dropdown-menu-item {
        @include border-radius(0px !important);
    }
    .ant-select-dropdown-menu-item:hover {
        background-color: $color_secondary !important;
    }
}


// nzSelect dropdown

.ant-select-selector {
	border-radius: 5px !important;
	
}
nz-select-arrow{
	.anticon{
		font-size: 13px !important;
	}
}
.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
	border-color: #d9d9d9 !important;
}
.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
	border-color: #d9d9d9 !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
	background-color: $color_secondary !important;
}

//PROGRESS BAR
.ant-progress-inner {
    background-color: $color_grey_8;
}
.ant-progress-bg {
    background-color: $color_primary;
}
.ant-progress-status-exception .ant-progress-bg {
    background-color: $color_primary !important;
}

.ant-progress-success-bg,
.ant-progress-status-success .ant-progress-bg {
    background-color: $color_primary !important;
}
.b-progress-medium {
    .ant-progress-inner,
    .ant-progress-bg,
    ant-progress-success-bg {
        height: $large_medium !important;
    }
}
.b-progress-large {
    .ant-progress-inner,
    .ant-progress-bg,
    ant-progress-success-bg {
        height: $large_progress !important;
    }
}
.b-progress-small {
    .ant-progress-inner,
    .ant-progress-bg,
    ant-progress-success-bg {
        height: $small_progress !important;
    }
}
.progress-white .ant-progress-inner {
    background-color: $color_white !important;
}

.progress-gray-6 .ant-progress-inner {
    background-color: $color_grey_6 !important;
}
.progress-bg-danger .ant-progress-bg {
    background-color: $color_danger !important;
}
//MODAL
.ant-modal-content {
	@include border-radius($modal_radius !important);
}
.ant-modal-mask {
    background-color: $modal_mask !important;
}
.ant-modal{
	top: 32px !important;
}
.b-modal {
    .ant-modal-content {
        @include border-radius($modal_radius !important);
        @include box-shadow($modal_shadow !important);
        padding: $modal_padding;
        border-top: $modal_border;
    }
    .ant-message,
    .ant-modal {
        font-family: $font_family !important;
    }
    .anticon-close {
        font-size: $p_size !important;
        background: $color_grey_7 !important;
        @include border-radius(30px !important);
        padding: 5px !important;
        color: $color_grey_2 !important;
    }
    .ant-modal-body {
        padding: 0 !important;
    }
    .ant-modal-footer {
        border: none !important;
        padding-bottom: 0px !important;
        padding-right: 0px !important;
        margin-top: 120px !important;
    }
    h1 {
        margin-top: 4px;
        margin-bottom: 34px;
        font-weight: $font_demi_bold !important;
    }
    .b-input {
        max-width: 100% !important;
    }
}

.b-modal-no-padding {
    .ant-modal-content {
        padding: 5px !important;
        max-width: 375px !important;
    }
    .ant-modal-content {
        border: none !important;
    }
}
.b-modal-no-border {
    .ant-modal-content {
        padding: 10px;
    }
    // .ant-modal {
    //     width: 668px !important;
    // }
    .ant-modal-content {
        border: none !important;
    }
}

.b-modal-no-padding {
    .ant-modal-content {
        padding: 0 !important;
        border: none !important;
    }
    .ant-modal {
        width: 668px !important;
    }
}

//Confirm modal
.ant-modal-confirm {
    width: 462px !important;
    .ant-modal-body {
        padding: 0px !important;
    }
    .ant-modal-confirm-body {
        margin: 47px !important;
    }
    .ant-modal-confirm-btns {
        float: none !important;
        margin-top: 20px;
        width: 100%;
        text-align: right;
        background: $color_grey_7;
        padding: 14px;
        padding-right: 40px;
        @include border-radius($modal_radius !important);
        .ant-btn {
            color: $color_grey_1;
            border: none;
            font-size: 17px;
            font-weight: $font_demi_bold;
            font-family: $font_family;
            display: inline-block;
            @include border-radius(6px !important);
            @include box-shadow($button_shadow !important);
            line-height: 0 !important;
            min-width: 139px;
            height: 45px;
            background: none;
            padding: 0px 32px 0px 32px;
        }
        .ant-btn-primary {
            border: none;
            text-align: center;
            color: $color_white;
            background-color: $color_danger;
            @include transition(0.1s);
            // border: 1px solid $color_danger;
            font-size: 17px;
            font-weight: $font_demi_bold;
            font-family: $font_family;
            min-width: 139px;
            height: 45px;
            padding: 0px 32px 0px 32px;
        }
    }
    .ant-modal-confirm-title {
        font-size: $h4_size !important;
        color: $color_grey_1 !important;
        font-family: $font_family !important;
        font-weight: $font_demi_bold !important;
    }
    .ant-modal-confirm-content {
        margin-left: 0px !important;
        font-size: $p_size !important;
        color: $color_grey_2 !important;
        font-family: $font_family !important;
        font-weight: $font_normal !important;
        margin-top: 24px;
    }
}

.ant-modal-confirm-success{
	.ant-btn-primary{
		background-color: $color_primary !important;
	}
}

//Notifications
.notif-title-wrapper {
    display: flex;
    justify-content: space-between !important;
    p {
        font-size: $p_size !important;
        font-weight: $font_demi_bold !important;
        font-family: $font_family !important;
    }
}
.ant-notification {
    .ant-notification-notice {
        @include border-radius(6px !important);
        padding: 16px !important;
        @include box-shadow($shadow_4 !important);
    }
    .ant-notification-notice-message {
        margin-left: 0px !important;
        padding-right: 16px !important;
    }
    .anticon {
        display: none !important;
    }
    .ant-notification-notice-message {
        font-size: $h5_size !important;
        color: $color_grey_1 !important;
        font-family: $font_family !important;
        font-weight: $font_demi_bold !important;
        width: 100%;
    }
    .ant-notification-notice-description {
        font-size: $p_size !important;
        color: $color_grey_2 !important;
        font-family: $font_family !important;
        margin-left: 0px !important;
        margin-top: 2px !important;
    }
    .ant-notification-notice-icon {
        margin-top: 2px !important;
    }
}
//LOADING skeleton
.nz-jobs-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        display: flex !important;
        padding-left: 0px !important;
    }
    li {
        width: 98px !important;
        height: calc(100vh - 252px) !important;
        margin-top: 100px !important;
        margin-right: 2px;
        @include border-radius(5px);
    }
}

.nz-jobs-city-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        display: flex !important;
        padding-left: 0px !important;
    }
    li {
        width: 98px !important;
        @include border-radius(5px);
        margin-top: 16px !important;
        margin-left: 42px !important;
        height: 20px !important;
    }
}
.nz-jobs-loading-margin {
    li {
        margin-top: 0px !important;
    }
}
.nz-shifts-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        display: flex !important;
        padding-left: 21px !important;
        margin-bottom: 1.5em !important;
    }
    li {
        margin-top: 15px !important;
        height: 25px !important;
        @include border-radius(5px);
        &:nth-child(1) {
            margin-right: 55px !important;
            width: 47px !important;
            min-width: 47px !important;
        }
        &:nth-child(2) {
            margin-right: 25px !important;
            width: 90px !important;
            min-width: 90px !important;
        }
        &:nth-child(3) {
            margin-right: 25px !important;
            width: 53px !important;
            min-width: 53px !important;
        }
        &:nth-child(4) {
            margin-right: 25px !important;
            width: 53px !important;
            min-width: 53px !important;
        }
        &:nth-child(5) {
            margin-right: 90px !important;
            width: 65px !important;
            min-width: 65px !important;
        }

        &:nth-child(6) {
            width: 100% !important;
        }
    }
}
.nz-more-shifts-loading {
    ul {
        padding-left: 0px !important;
        margin-bottom: 2px !important;
    }
}
.nz-application-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        margin-bottom: 0px !important;
        li {
            width: 14px !important;
            height: 14px !important;
            @include border-radius(50px !important);
            margin: auto !important;
        }
    }
}
.nz-applicant_avatar-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        display: inline-flex;
        margin-bottom: 0px !important;
        li {
            width: 45px !important;
            height: 45px !important;
            @include border-radius(50px !important);
            margin: auto !important;
            margin: 4px 12px 8px 12px !important;
        }
    }
}
.nz-applicant-loading {
    .ant-skeleton-avatar {
        width: 68px;
        height: 68px;
    }
    ul {
        margin-bottom: 0px !important;

        li {
            width: 90% !important;
            margin-top: 19px !important;
            height: 24px;
        }
    }
}

.ant-avatar {
    background: $color_primary !important;
    vertical-align: middle;
    position: initial !important;
    img {
        object-fit: cover;
    }
    .ant-avatar-string {
        position: initial !important;
        font-family: $font_family !important;
    }
}

.ant-avatar-lg {
    width: 75px !important;
    height: 75px !important;
    line-height: 75px !important;
    .ant-avatar-string {
        font-size: $h4_size !important;
        font-weight: $font_demi_bold !important;
    }
}

.ant-avatar-sm {
    width: 45px !important;
    height: 45px !important;
    line-height: 45px !important;
}
.nz-avatar-xs {
    width: 40px !important;
    height: 40px !important;
    line-height: 40px !important;
}
.nz-cites-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        padding-left: 0px !important;
    }
    li {
        width: 100% !important;
        height: 90px !important;
        margin-top: 90px !important;
        margin-right: 0px;
        margin-left: 0px;
    }
}
.nz-sites-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        padding-left: 0px !important;
    }
    li {
        width: 100% !important;
        height: 80px !important;
        margin-top: 80px !important;
        margin-right: 0px;
        margin-left: 0px;
    }
}

.nz-staff-loading {
    padding: 1% 4% 1% 4% !important;
}

.nz-timesheets-loading {
    .ant-skeleton-header {
        display: none;
    }
    ul {
        padding-left: 0px !important;
    }
    li {
        width: 100% !important;
        height: 80px !important;
        margin-right: 0px;
        margin-left: 0px;
    }
}
.nz-profile-loading {
    .ant-skeleton-header {
        display: block !important;
        text-align: center !important;
        margin-bottom: 20px !important;
        .ant-skeleton-avatar {
            width: 76px !important;
            height: 76px !important;
        }
    }
    .ant-skeleton-content {
        display: block;
    }
    ul {
        padding-left: 0px !important;
    }
    li {
        width: 100% !important;
        height: 25px !important;
        margin-right: 0px;
        margin-left: 0px;
    }
    li:last-child {
        height: 15px !important;
        height: 150px !important;
        &:nth-child(1) {
            width: 200px !important;
            margin: auto !important;
            height: 30px !important;
            margin-bottom: 15px !important;
        }
        &:nth-child(2) {
            width: 150px !important;
            margin: auto !important;
            height: 20px !important;
            margin-bottom: 80px !important;
        }
    }
}

//DRAWER
.ant-drawer-content {
    background: $color_white !important;
}
.ant-drawer-body {
    padding-top: 16px !important;
    padding-bottom: 16px !important;
}

//Rating
.nz-profile-rate {
    .ant-rate {
        color: $color_grey_1 !important;
        .ant-rate-star > div:focus,
        .ant-rate-star > div:hover {
            transform: initial !important;
        }
        .anticon {
            background: none !important;
            cursor: initial !important;
        }
        .ant-rate-star {
            margin: 0px 3px 0 0 !important;
        }
    }
}
//CAROUSEL

.ant-carousel {
    .slick-dots li button {
        width: 14px !important;
        height: 14px !important;
        border-radius: 50px !important;
        margin-right: 14px;
    }
	.slick-slider {
		position: initial !important;
	}
}

//SWITCH
.nz-override-switch {
    .ant-switch {
        width: 82px !important;
        height: 34px !important;
        background: $color_white !important;
        @include box-shadow($shadow_1 !important);
    }
    .ant-switch:focus {
        @include box-shadow($shadow_1 !important);
    }

    .ant-click-animating-node {
        display: none !important;
    }
	.ant-switch-checked .ant-switch-handle {
		left: calc(100% - 30px - 2px) !important;
	}
	.ant-switch-handle{
		width: 27px !important;
		height: 27px !important;
		top: 4px !important;
		left: 5px !important;
	}
	.ant-switch-checked .ant-switch-handle::before{
		background-color: $color_primary !important;
		box-shadow: none !important;
	}
	.ant-switch-handle::before{
		border-radius: 30px !important;
		background-color: $color_grey_5 !important;
		box-shadow: none !important;
	}
}
//ToolTip
.ant-tooltip {
    max-width: 250px;
}

//Pagination

.b-nz-pagination {
    .ant-pagination-item-link .anticon {
        font-size: 12px !important;
        background: none !important;
    }
    .ant-pagination.mini .ant-pagination-next,
    .ant-pagination.mini .ant-pagination-prev {
        line-height: 23px !important;
        background: none !important;
    }
    .ant-pagination-item-active a {
        border: none !important;
        font-weight: $font_demi_bold !important;
        color: $color_grey_1 !important;
        background: none !important;
    }
    .ant-pagination-item {
        border: none !important;
        color: $color_grey_2 !important;
        background: none !important;
    }
    .ant-pagination-item a:hover {
        font-weight: $font_demi_bold !important;
        color: $color_grey_1 !important;
    }
    .ant-pagination-next:focus .ant-pagination-item-link,
    .ant-pagination-next:hover .ant-pagination-item-link,
    .ant-pagination-prev:focus .ant-pagination-item-link,
    .ant-pagination-prev:hover .ant-pagination-item-link {
        color: $color_grey_1 !important;
        background: none !important;
    }
    .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon,
    .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon {
        color: $color_grey_2 !important;
        background: none !important;
    }
    .ant-pagination-item a {
        color: $color_grey_2;
        // font-weight: normal !important;
    }
}

//POPOVER

// .ant-popover-arrow {
//     display: none !important;
// }

.ant-popover-content {
    .ant-popover-inner {
        background: none !important;
        @include box-shadow(none !important);
    }
}
.ant-popover-inner-content {
    padding: 0px !important;
}

//slider
.ant-slider {
    .ant-slider-rail {
        height: 9px !important;
    }
    .ant-slider-track {
        background-color: $color_primary !important;
        height: 9px !important;
    }
    .ant-slider-handle {
        width: 23px !important;
        height: 23px !important;
        margin-top: -6px !important;
        margin-left: -5px !important;
        background: $color_white !important;
        border: 3px solid $color_primary !important;
    }
}
