/* You can add global styles to this file, and also import other style files */
@import "../src/components/styles-theme/index.scss";

//FONTS
@font-face {
    font-family: "AvenirNext";
    font-style: normal;
    font-weight: normal;
    src: url("../src/assets/fonts/avenirNext/AvenirNext-Regular.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNext";
    font-style: normal;
    font-weight: 800;
    src: url("../src/assets/fonts/avenirNext/AvenirNext-Bold.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNext";
    font-style: normal;
    font-weight: 900;
    src: url("../src/assets/fonts/avenirNext/AvenirNext-Heavy.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNext";
    font-style: demi-bold;
    font-weight: 600;
    src: url("../src/assets/fonts/avenirNext/AvenirNext-DemiBold.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNext";
    font-style: medium;
    font-weight: 500;
    src: url("../src/assets/fonts/avenirNext/AvenirNext-Medium.ttf") format("truetype");
}
@font-face {
    font-family: "AvenirNext";
    font-style: light;
    font-weight: 200;
    src: url("../src/assets/fonts/avenirNext/AvenirNext-UltraLight.ttf") format("truetype");
}

body,
html {
    height: 100%;
    margin: 0 auto;
}

body {
    font-family: "AvenirNext" !important;
    font-size: 18px !important;
    //   background-color: $color_grey_7 !important;
}

a {
    text-decoration: none !important;
}

section {
    min-width: 1024px !important;
    padding: 1em;
    background: $color_grey_7 !important;
    min-height: calc(100% - 106px); // minus the header
}

@media (max-width: 768px) {
    section {
        min-width: 100% !important;
    }
}
//Content wrapper
.content-wrapper {
    width: 82%;
    margin: auto;
    margin-top: 35px;
    margin-bottom: 260px;
}
@media screen and (max-width: 1024px) {
    .content-wrapper {
        width: 100% !important;
    }
}
@media screen and (max-width: 1501px) {
    .content-wrapper {
        width: 90%;
    }
}

.post-head-wrapper {
    display: flex;
    justify-content: space-between !important;
    margin-bottom: 20px;
}

.group-buttons {
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
}

:root {
    --color_primary: #1dc7b2;
    --color_secondary_1: #c4efea;
    --color_secondary: #ebfffc;
    --color_primary_intense: #1fa695;

    --color_grey_1: #4a4a4a;
    --color_grey_2: #9b9b9b;
    --color_grey_3: #9aa5b1;
    --color_grey_4: #d0d8e0;
    --color_grey_5: #e6e6e6;
    --color_grey_6: #f0f0f0;
    --color_grey_7: #fbfcfd;
    --color_grey_8: #f7f7f7;

    --color_yellow: #ffcf57;
    --color_danger: #fd6d6d;
    --color_purple: #9013fe;
    --color_white: #fff;
    --color_black: #000;

    --color_blue_light: #fbfdff;

    --color_grey_2_ultra: rgba(155, 155, 155, 0.25); // light_gray with 0.25 opacity
    --shadow_color: rgba(0, 0, 0, 0.2);
    --shadow_color_light: rgba(228, 228, 228, 0.5);
}
